<template>
  <v-dialog v-model="dialog" width="1100" persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Importar .CSV - Blocklist</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-container>
        <form enctype="multipart/form-data" class="ma-4">
          <input ref="fileUpload" accept=".csv" type="file" @change="onFileChange" />
        </form>
        <v-divider />
        <v-alert v-if="readedFromCsv === 0" type="error" outlined dense class="ma-4">Não foi possível processar os usuários importados</v-alert>
        <v-card-title v-if="readedFromCsv">Total de usuários processados: {{ readedFromCsv }}</v-card-title>
        <v-row no-gutters>
          <v-btn v-if="readedFromCsv" :disabled="!readedFromCsv" class="ma-2" color="error" outlined @click="blocklistUsers('add')"
            ><v-icon class="mr-1">mdi-plus</v-icon> Inserir usuários na Blocklist</v-btn
          >
          <v-btn v-if="readedFromCsv" :disabled="!readedFromCsv" class="ma-2" color="primary" outlined @click="blocklistUsers('remove')"
            ><v-icon class="mr-1">mdi-cancel</v-icon> Remover usuários da Blocklist</v-btn
          >
          <v-btn v-if="readedFromCsv" class="ma-2" color="info" @click="preview = true"
            ><v-icon class="mr-2">mdi-file-find</v-icon> Prévia de usuários processados</v-btn
          >
        </v-row>
      </v-container>
    </v-card>

    <!-- import preview -->
    <v-dialog v-if="users" v-model="preview" width="700" persistent transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="preview = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Prévia dos usuários importados</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-container>
          <v-row no-gutters>
            <v-chip v-for="user in users" :key="user.index" class="ma-1">{{ user }}</v-chip>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { MUTATION_BLOCKLIST_USERS_FROM_CSV } from '@/modules/accounts/graphql'

export default {
  props: {
    dialog: Boolean,
    accountId: String
  },
  data: () => ({
    readedFromCsv: null,
    users: [],
    preview: false
  }),
  methods: {
    async onFileChange(e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        const readed = e.target.result
          .toString()
          .replaceAll('\r', '')
          .split('\n')
          .filter(n => n)
        this.readedFromCsv = readed.length || 0
        this.users = readed || []
      }
      reader.readAsText(file)
    },
    close() {
      this.$emit('close')
      this.readedFromCsv = null
      this.users = []
      this.$refs.fileUpload.value = null
    },
    async blocklistUsers(e) {
      const verbsMessage = []
      if (e === 'add') verbsMessage.push('inseridos', 'inserir')
      if (e === 'remove') verbsMessage.push('removidos', 'remover')

      const variables = {
        account_id: this.accountId,
        document_no: this.users,
        flag: e
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_BLOCKLIST_USERS_FROM_CSV,
          variables
        })
        this.$snackbar({ message: `Usuários ${verbsMessage[0]} com sucesso`, snackbarColor: '#2E7D32' })
        setTimeout(() => {
          this.$router.go()
        }, 200)
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: `Falha ao ${verbsMessage[1]} usuários ou usuários não encontrados`, snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
